import {Outlet} from "react-router";

const RootLayout = () => (
    <div className="background">
        {/*<header className="pt-3">*/}
        {/*    <Header />*/}
        {/*</header>*/}
        <main>
            <Outlet/>
        </main>
    </div>
)

export default RootLayout;