import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/css';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
import {Image} from "react-bootstrap";
import git from '../../../../portfolio/assets/image/gi.png';
import react from "../../../../portfolio/assets/image/react.png";
import ts from "../../../../portfolio/assets/image/ts.png";
import nextjs from "../../../../portfolio/assets/image/nextjs.png"
import bootstrap from "../../../../portfolio/assets/image/bootstrap.png"
import sass from "../../../../portfolio/assets/image/sass.png"

const CustomSplideComponent = ({image}) => {
    return (
        <SplideSlide>
            <Image src={image}/>
        </SplideSlide>
    )
}

const TechStack = () => {
    return (
        <Splide className="usercard-bg col-md-10 col-12 rounded-4 py-1"
                options={{
                    type: 'loop',
                    drag: 'free',
                    focus: 'center',
                    pagination: false,
                    gap: 200,
                    arrows: false,
                    perPage: 5,
                    breakpoints: {
                        640: {
                            perPage: 3
                        }
                    },
                    autoScroll: {
                        speed: -1,
                    },
                }}
                extensions={{AutoScroll}}>
                <CustomSplideComponent image={git}/>
                <CustomSplideComponent image={react}/>
                <CustomSplideComponent image={ts}/>
                <CustomSplideComponent image={nextjs}/>
                <CustomSplideComponent image={bootstrap}/>
                <CustomSplideComponent image={sass}/>
        </Splide>
    )
}

export default TechStack