import {Route, Routes} from "react-router";
import RootLayout from "../layout/RootLayout";
import AllPage from "../module/all/AllPage";

const PrivateRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<RootLayout/>} >
                <Route index element={<AllPage/>}/>
            </Route>
        </Routes>
    )
}

export {PrivateRoute};