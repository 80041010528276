import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/splide/css';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';

const SplideComponent = () => {
    return <SplideSlide>
        <h3 className="font-inter text-center text-nowrap"> VADH KHIEV</h3>
    </SplideSlide>
}

const ReactRoll = () => {
    return (
        <Splide className="usercard-bg bg-dark text-white p-3 rounded-4 ms-md-2 mt-md-0 mt-1"
                options={{
                    type: 'loop',
                    drag: 'free',
                    focus: 'center',
                    pagination: false,
                    gap: 200,
                    arrows: false,
                    perPage: 4,
                    breakpoints: {
                        640: {
                            perPage: 3
                        }
                    },
                    autoScroll: {
                        speed: -1,
                    },
                }}
                extensions={{AutoScroll}}>
            <SplideComponent/>
        </Splide>
    )
}


export default ReactRoll;