import linkedin_logo from "../../../../portfolio/assets/image/linkedin.png";
import gitlab_logo from "../../../../portfolio/assets/image/gitlab.png";
import github_logo from "../../../../portfolio/assets/image/github.png";
import telegram_logo from "../../../../portfolio/assets/image/telegram.png";

const header_data = [
    "All",
    "About",
    "Work"
]

const learnmore_data = [
    {
        title: "Github",
        image: github_logo,
    }, {
        title: "LinkedIn",
        image: linkedin_logo,
    }, {
        title: "Gitlab",
        image: gitlab_logo
    }, {
        title: "Telegram",
        image: telegram_logo,
    }
]

export {
    header_data,
    learnmore_data,
}