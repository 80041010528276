import {Container} from "react-bootstrap";
import UserCard from "./component/UserCard";
import Resume from "./component/Resume";
import ReactRoll from "./component/ReactRoll";
import LearnMore from "./component/LearnMore";
import Experience from "./component/Experience";
import TechStack from "./component/TechStack";

const AllPage = () => {
    return (
        <Container className="pt-5">
            <section className="d-flex justify-content-center align-items-center flex-wrap">
                <div className="col-12 col-md-6">
                    <UserCard/>
                </div>
                <div className="col-md-4 col-12">
                    <ReactRoll/>
                    <Resume/>
                </div>
            </section>


            <section className="d-flex justify-content-center align-content-center flex-wrap mt-1">

                <div className="col-12 col-md-4 d-flex flex-wrap justify-content-around gap-1 justify-content-md-start">
                    <LearnMore/>
                </div>

                <div className="col-md-6 col-12 usercard-bg p-2">
                    <Experience/>
                </div>

            </section>


            <section className="d-flex justify-content-center align-content-center flex-wrap mt-1">
                <TechStack/>
            </section>

        </Container>
    )
}

export default AllPage;