import {RiDownload2Line, RiEye2Line} from "@remixicon/react";

function Resume() {
    return (
        <div className="border d-flex align-content-center gap-3 p-3 mt-md-3 mt-1 ms-md-2 rounded-4 bg-white usercard-bg justify-content-center">
            <h3 className="text-center pt-2 fw-bold">Resume</h3>
            <div>
                <button className={`border bg-white rounded-4 p-3 mx-2`}><RiDownload2Line/></button>
                <button className={`border bg-white rounded-4 p-3 mx-2`}><RiEye2Line/></button>
            </div>
        </div>
    );
}

export default Resume;