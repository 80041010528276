import avatar from "../../../../portfolio/assets/avatar.jpg"
import {Image} from "react-bootstrap";

const UserCard = () => {
    return (
        <div className="usercard-bg border border-1 px-5 py-3 rounded-4">
            <div className="d-flex">
                <div className="bg-dark rounded-circle border border-1 text-white text-center col-2" style={{height:'80px', width:'80px'}}>
                    <Image fluid className="rounded-circle" src={avatar} />
                </div>
                <div className="fs-2 fw-semibold ms-4 pt-md-2 pt-3 col-10">
                    Vivadh Khiev
                </div>
            </div>
            <div className="font-inter">
                I'm a software engineer currently working as a <b>Frontend React Developer</b> at KiloIT and studying senior year at Royal University of Phnom Penh.
            </div>
        </div>
    )
}

export default UserCard;