import {learnmore_data} from "../core/all_data";
import React from "react";
import {Image} from "react-bootstrap";

const LearnMore = () => {
    return (
        <>

            {
                learnmore_data.map(({title, image})=>{
                    return (
                        <div key={title} className={`col-5 d-flex justify-content-center border border-2 p-3 p-md-2 usercard-bg`}>
                            <Image src={image}/>
                        </div>
                    )
                })
            }
        </>
    )
}

export default LearnMore;