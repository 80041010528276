const Experience = () => {
    return (
        <>
            <header className="ps-3">
                <div>1 Year+</div>
                <div className="fs-4 fw-semibold">Experience</div>
            </header>
            <section className="d-flex ps-3 mt-2 justify-content-between flex-wrap ">
                <div className="col-12 col-md-9">
                    <h1 className="font-inter">Frontend Developer</h1>
                </div>
                <div className="d-flex flex-wrap col-3">
                    <div className="col-12 fw-semibold fs-5">
                        KiloIT
                    </div>
                    <div className="col-12 fw-lighter">
                        2024-Present
                    </div>
                </div>
            </section>
            <section className="d-flex ps-3 mt-2 justify-content-between flex-wrap opacity-75">
                <div className="col-12 col-md-9">
                    <h1 className="font-inter text-decoration-line-through text-dark-gray">ReactJS Intern</h1>
                </div>
                <div className="d-flex flex-wrap col-3">
                    <div className="col-12 fw-semibold fs-5">
                        KiloIT
                    </div>
                    <div className="col-12 fw-light">
                        June 23-April 24
                    </div>
                </div>
            </section>

        </>
    )
}

export default Experience;